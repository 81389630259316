<template>
	<b-modal id="cancel-inventory-session" title="Cancel Inventory Session" ref="modal" ok-title="Proceed" ok-variant="danger" 
		cancel-title="Close" @ok="handleOk" @show="onReset" :cancel-disabled="disableConfirmButtons" 
		:ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<div class="confirm-message">
			Are you sure you want to <b>cancel</b> session <strong class="numFont">{{ sessionId }}</strong>?
		</div>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { InventorySessionUtil } from '@/utils/inventorySessionUtil';

// API
import sessionAPI from '@/api/inventorySessionApi';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'cancel-inventory-session',
	components: {
		Loading,
	},
	data() {
		return {
			inventorySession: {},

			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		sessionId() {
			return this.inventorySession && this.inventorySession.sessionId ? this.inventorySession.sessionId : '-';
		}
	},
	mounted() {
		EventBus.$on('onUpdateInventorySession', (inventorySession) => {
			this.inventorySession = InventorySessionUtil.cleanupFields(inventorySession);
		});
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			this.inventorySession['status'] = 'Cancelled';
			this.inventorySession['dateCancelled'] = DateUtil.getCurrentTimestamp();
			this.inventorySession['cancelledBy'] = this.loggedUser.id;

			await this.cancelInventorySession();
		},
		async cancelInventorySession() {
			try {
				let { data } = await sessionAPI.cancelInventorySession(
					this.inventorySession,
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					EventBus.$emit('onCloseSaveSession', data.inventorySession);
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(`Error cancelling Inventory Session "${this.sessionId}". Please try again.`);
				}
			} catch (_error) {
				this.$toaster.error(`Error cancelling Inventory Session "${this.sessionId}". Please try again.`);
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
		onReset() {
			// do nothing
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateInventorySession');
	},
};
</script>