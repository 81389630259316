import { DateUtil } from '@/utils/dateutil';
import config from '@/config/env-constants';


export const AssetUtil = {

	generateAssetCodes(prefix, noOfAssets, lastCreatedAssetCode) {
		let assetCodes = [];
		let index = 1;
		let currentAssetId = lastCreatedAssetCode;
		while (index <= noOfAssets) {
			currentAssetId = this.generateNextId(currentAssetId);
			assetCodes.push(currentAssetId);
			index++;
		}
		return assetCodes;
	},

	generateNextId(val) {
		let nextId = '';
		for (let index = val.length - 1; index > 1; index--) {
			const character = val.charAt(index);
			const nextCharacter = this.getNextCharacter(character);
			if (nextCharacter === '0') {
				nextId = nextCharacter + nextId;
				//it means we need to add one to the previous characters
			} else {
				const firstChars = val.substring(0, index); //includes the prefix
				nextId = firstChars + nextCharacter + nextId;
				break;
			}
		}

		return nextId;
	},

	getNextCharacter(character) {
		let nextCharacter;
		if (character === '9') {
			nextCharacter = 'a';
		} else if (character === 'h') {
			//skip i
			nextCharacter = 'j';
		} else if (character === 'k') {
			//skip l
			nextCharacter = 'm';
		} else if (character === 'n') {
			//skip o
			nextCharacter = 'p';
		} else if (character === 'z') {
			nextCharacter = '0';
		} else {
			nextCharacter = String.fromCharCode(character.charCodeAt(character.length - 1) + 1);
		}
		return nextCharacter;
	},

	createAsset: (assetCode, userId, assetTypeCodeObj) => {
		let assetDetails = {
			assetCode: assetCode,
			name: assetTypeCodeObj.assetType,
			serialNo: "",
			condition: "",
			category: "",
			description: "",
			imgUrl: "",
			storageLocation: "",
			storageLocationId: "",
			inventorySessionId: "",
			lastInventoryDate: 0,
			inventoriedBy: "",
			dateUpdated: DateUtil.getCurrentTimestamp(),
			updatedBy: userId
		};

		return {
			assetId: assetCode,
			assetCode: assetCode,
			status: config.assetStatus.INACTIVE,
			assetType: assetTypeCodeObj.assetTypeName,
			assetTypeId: assetTypeCodeObj.assetTypeId,
			assetTypeCode: assetTypeCodeObj.codeName,
			details: assetDetails,
			origin: assetTypeCodeObj.origin,
			originId: assetTypeCodeObj.originId,
			currCompany: "",
			currCompanyId: "",
			dateCreated: DateUtil.getCurrentTimestamp(),
			createdBy: userId,
			dateUpdated: DateUtil.getCurrentTimestamp(),
			updatedBy: userId,
			dateActivated: null,
			activatedBy: "",
			dateDeactivated: null,
			deactivatedBy: "",
			nextLocation: {},
			currentLocation: {},
			stickerCopies: 0
		}
	},

	createCustomAsset: (assetCode, userId, assetTypeObj) => {
		let assetDetails = {
			assetCode: assetCode,
			name: assetTypeObj.name,
			serialNo: "",
			condition: "",
			category: "",
			description: "",
			imgUrl: "",
			storageLocation: "",
			storageLocationId: "",
			inventorySessionId: "",
			lastInventoryDate: 0,
			inventoriedBy: "",
			dateUpdated: DateUtil.getCurrentTimestamp(),
			updatedBy: userId
		};

		return {
			assetId: assetCode,
			assetCode: assetCode,
			status: config.assetStatus.INACTIVE,
			assetType: assetTypeObj.name,
			assetTypeId: assetTypeObj.id,
			assetTypeCode: "",
			details: assetDetails,
			origin: assetTypeObj.origin,
			originId: assetTypeObj.originId,
			currCompany: "",
			currCompanyId: "",
			dateCreated: DateUtil.getCurrentTimestamp(),
			createdBy: userId,
			dateUpdated: DateUtil.getCurrentTimestamp(),
			updatedBy: userId,
			dateActivated: null,
			activatedBy: "",
			dateDeactivated: null,
			deactivatedBy: "",
			nextLocation: {},
			currentLocation: {},
			stickerCopies: 0
		}
	},

	getAssetTypeCodeByPrefix: (prefix, assetTypeCodesArr) => {
		for (let assetTypeCodeObj of assetTypeCodesArr) {
			if (assetTypeCodeObj.prefix === prefix) {
				return assetTypeCodeObj;
			}
		}
		return {};
	}

}
