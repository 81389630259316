import axios from 'axios';
import config from '@/config/env-constants';


function getProjectId() {
    if (config.currEnv === 'pjl') {
        return `ayun-${config.currEnv}`;
    } else {
        return `ares-${config.currEnv}-app`;
    }
}

export default {

    baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,

    getInventorySessions(filterBy, view, currUserId) {
        const url = `${this.baseUrl}/getInventorySessions`;
        return axios.post(url, {
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    },

    addInventorySession(inventorySession, currUserId, currTimeStamp) {
        let url = `${this.baseUrl}/addInventorySession`;
        return axios.post(url, {
            currUserId: currUserId,
            currTimeStamp: currTimeStamp,
            inventorySession: JSON.stringify(inventorySession)
        });
    },

    updateInventorySession(inventorySession, currUserId, currTimeStamp) {
        let url = `${this.baseUrl}/updateInventorySession`;
        return axios.post(url, {
            currUserId: currUserId,
            currTimeStamp: currTimeStamp,
            inventorySession: JSON.stringify(inventorySession)
        });
    },

    cancelInventorySession(inventorySession, currUserId, currTimeStamp) {
        let url = `${this.baseUrl}/cancelInventorySession`;
        return axios.post(url, {
            currUserId: currUserId,
            currTimeStamp: currTimeStamp,
            inventorySession: JSON.stringify(inventorySession)
        });
    },

    markInventorySessionAsDone(inventorySession, assets, currUserId, currTimeStamp) {
        let url = `${this.baseUrl}/markInventorySessionAsDone`;
        return axios.post(url, {
            currUserId: currUserId,
            currTimeStamp: currTimeStamp,
            inventorySession: JSON.stringify(inventorySession),
            assets: JSON.stringify(assets)
        });
    },

}