<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-cubes"></i> Inventory Session</b-card-title>
			<b-card-sub-title>Manages the inventory sessions for all companies</b-card-sub-title>
			<div fluid class="px-2 mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Filter  -->
				<b-row class="mt-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>
					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2">
							<b-card>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Date From" description="Reference to the Date Created">
											<b-form-datepicker v-model="filterBy.dateFrom" locale="en" reset-button
												label-reset-button="Clear" :date-format-options="{
													year: 'numeric',
													month: 'short',
													day: '2-digit',
													weekday: 'short',
												}" :date-disabled-fn="dateFromDisabled" />
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Date To" description="Reference to the Date Created">
											<b-form-datepicker v-model="filterBy.dateTo" locale="en" reset-button
												label-reset-button="Clear" :date-format-options="{
													year: 'numeric',
													month: 'short',
													day: '2-digit',
													weekday: 'short',
												}" :date-disabled-fn="dateFromDisabled" />
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Company">
											<v-select class="style-chooser" label="text"
												placeholder=" - Please select - " :options="allCompanyOptions"
												:reduce="(company) => company.value" v-model="filterBy.company">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Status">
											<b-form-select v-model="filterBy.status" :options="statusOptions"
												class="mr-2" />
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Asset Code"
											description="Asset code included from existing sessions">
											<b-form-input id="assetCode" name="Asset Code" type="search" class="numFont"
												v-model="filterBy.assetCode"
												v-validate="{ regex: /^([a-zA-Z0-9\-])*$/ }" />
											<span v-show="errors.has('Asset Code')" class="help-block">{{
												errors.first('Asset Code') }}</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Session ID"
											description="NOTE: Input the exact Session ID to search">
											<b-form-input id="sessionId" name="Session ID" type="search" class="numFont"
												v-model="filterBy.sessionId"
												v-validate="{ required: false, regex: /^IS\d{13}$/ }"
												placeholder="ISXXXXXXXXXXXXX" />
											<span v-show="errors.has('Session ID')" class="help-block">{{
												errors.first('Session ID') }}</span>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilterRequest">
											Search
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<!-- Select Actions and Items Per Page Options -->
				<b-row>
					<b-col sm="6" md="3" class="mt-4 mb-2">
						<b-dropdown text=" Select Actions " variant="dark" slot="append">
							<b-dropdown-item @click="validateAddInventorySession()" v-show="!isViewer">
								Add Inventory Session
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportInventoryData" :fields="exportInventoryFields" type="xls"
									:name="fileName + '.xls'">
									Export Inventory Sessions in Excel
								</json-excel>
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportInventoryData" :fields="exportInventoryFields" type="csv"
									:name="fileName + '.csv'">
									Export Inventory Sessions to CSV
								</json-excel>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table ref="inventoriesTable" show-empty striped hover :items="items" :fields="fields"
					:current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered" responsive>

					<template v-slot:cell(sessionId)="row">
						<span class="numFont">{{ row.item.sessionId }}</span>
					</template>

					<template v-slot:cell(total)="row">
						<span class="numFont">{{ row.item['Total'] }}</span>
					</template>

					<template v-slot:cell(storageLocation)="row">
						{{ row.item['Storage Location'] }}
					</template>

					<template v-slot:cell(status)="row">
						<InventoryRowStatus :row="row" />
					</template>

					<template v-slot:cell(dateCreated)="row">
						{{ showFormattedDate(row.item.dateCreated) }}
					</template>

					<template v-slot:cell(actions)="row">
						<InventoryRowActions :row="row" :isSuperAdmin="isSuperAdmin" :isViewer="isViewer"
							:params="params" />
					</template>

					<template slot="row-details" slot-scope="row">
						<InventoryDetailsView :row="row" />
					</template>
				</b-table>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>
			</div>
		</b-card>

		<!-- Modals here -->
		<AddInventorySession :allCompanyOptions="allCompanyOptions"
			:allStorageLocationOptions="allStorageLocationOptions" :allUserOptions="allUserOptions"
			:isSuperAdmin="isSuperAdmin" />
		<EditInventorySession :allCompaniesObj="allCompaniesObj" :allStorageLocationsObj="allStorageLocationsObj"
			:allCompanyOptions="allCompanyOptions" :allStorageLocationOptions="allStorageLocationOptions"
			:allUserOptions="allUserOptions" />
		<MarkAsDoneInventorySession :allAssetTypesObj="allAssetTypesObj" :allAssetTypeCodesObj="allAssetTypeCodesObj" />
		<CancelInventorySession />
		<ScannedAssetsView />
	</div>
</template>

<script>
// Components
import MarkAsDoneInventorySession from '@/views/transactions/inventory/MarkAsDoneInventorySession';
import AddInventorySession from '@/views/transactions/inventory/AddInventorySession';
import EditInventorySession from '@/views/transactions/inventory/EditInventorySession';
import CancelInventorySession from '@/views/transactions/inventory/CancelInventorySession';
import InventoryRowStatus from '@/views/transactions/inventory/InventoryRowStatus';
import InventoryRowActions from '@/views/transactions/inventory/InventoryRowActions';
import InventoryDetailsView from '@/views/transactions/inventory/InventoryDetailsView';
import ScannedAssetsView from '@/views/transactions/dispatch/ScannedAssetsView';

// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ValidationUtil } from '@/utils/validationUtil';
import { UserUtil } from '@/utils/userutil';

// API
import sessionAPI from '@/api/inventorySessionApi';

// DAO
import inventorySessionDAO from '@/database/inventorySessions';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import config from '@/config/env-constants';
import moment from 'moment';
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';
import { InventorySessionUtil } from '../../utils/inventorySessionUtil';

export default {
	name: 'inventory-session',
	components: {
		MarkAsDoneInventorySession,
		AddInventorySession,
		EditInventorySession,
		CancelInventorySession,
		InventoryRowStatus,
		InventoryRowActions,
		InventoryDetailsView,
		ScannedAssetsView,
		Loading,
		JsonExcel,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'sessionId',
					label: 'Session ID',
					sortable: true,
				},
				{
					key: 'dateCreated',
					sortable: true,
				},
				{
					key: 'company',
					sortable: true,
				},
				{
					key: 'storageLocation',
					sortable: false,
				},
				{
					key: 'total',
					sortable: true,
				},
				{
					key: 'status',
					sortable: true,
				},
				{
					key: 'actions',
					thClass: 'text-center'
				}
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			defaultFilterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				company: config.companyDefaultValue,
				status: null,
				sessionId: '',
				assetCode: '',
			},
			filterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				company: config.companyDefaultValue,
				status: null,
				sessionId: '',
				assetCode: '',
			},
			prevFilterBy: {},

			allCompanyOptions: [],
			allStorageLocationOptions: [],
			allUserOptions: [],
			statusOptions: config.inventorySessionStatus,

			allCompaniesObj: {},
			allUsersObj: {},
			allStorageLocationsObj: {},
			allAssetTypesObj: {},
			allAssetTypeCodesObj: {},
			allInventorySessionsObj: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,

			// Listener
			inventorySessionListener: null,
		};
	},
	computed: {
		dateFrom() {
			const dateTo = moment();
			const dateFrom = dateTo.add(-30, 'days');
			return dateFrom.format('YYYY-MM-DD');
		},
		dateTo() {
			return moment().format('YYYY-MM-DD');
		},

		params() {
			return {
				allStorageLocationsObj: this.allStorageLocationsObj,
				allAssetTypesObj: this.allAssetTypesObj,
				allAssetTypeCodesObj: this.allAssetTypeCodesObj,
			};
		},
		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportInventoryData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportInventoryFields() {
			return {
				'Session ID': 'sessionId',
				'Date Created': 'Date Created',
				Company: 'company',
				'Storage Location': 'Storage Location',
				Total: 'Total',
				Status: 'status',
				Description: 'description',
				'Assets': 'scannedAssets',
				'In-Transit Assets': 'inTransitAssets',
				'Deleted Assets': 'assetsToDelete',
				'Date Updated': 'Date Updated',
				'Date Cancelled': 'Date Cancelled',
				'Created By': 'createdBy',
				'Updated By': 'updatedBy',
				'Cancelled By': 'cancelledBy',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return 'Inventory-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
		},
	},
	mounted() {
		setTimeout(async () => {
			try {
				// Filter Access
				if (this.$store.getters.isScanner) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// show loading indicator
				this.isLoading = true;

				this.allCompaniesObj = { ...this.$store.getters.companies };
				this.allUsersObj = { ...this.$store.getters.users };
				this.allStorageLocationsObj = { ...this.$store.getters.storageLocations };
				this.allAssetTypesObj = { ...this.$store.getters.assetTypes };
				this.allAssetTypeCodesObj = { ...this.$store.getters.assetTypeCodes };

				// Retrieve filter options
				this.allCompanyOptions = DropDownItemsUtil.retrieveActiveCompanies(this.allCompaniesObj);
				this.allStorageLocationOptions = DropDownItemsUtil.retrieveActiveStorageLocations(this.allStorageLocationsObj);
				this.allUserOptions = DropDownItemsUtil.retrieveActiveUsers(this.allUsersObj);

				if (!this.isSuperAdmin) {
					this.defaultFilterBy.company = DropDownItemsUtil.getCompanyItem(this.loggedUserCompany);
				}
				this.defaultFilterBy.dateFrom = this.dateFrom;
				this.defaultFilterBy.dateTo = this.dateTo;
				await this.resetFilters();
				await this.retrieveData();

			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		}, config.timeout);

		// Event Listeners
		EventBus.$on('onCloseSaveSession', (inventorySessionObj) => {
			this.updateTable(inventorySessionObj);
		});
	},
	methods: {
		validateAddInventorySession() {
			let onGoingSessions = _.filter(this.allInventorySessionsObj, o => {
				return o.status === 'On-Going' || o.status === 'Draft';
			});

			if (!_.isEmpty(onGoingSessions) && !this.isSuperAdmin) {
				this.$toaster.warning('There is a Draft or On-Going session in your list. Mark all these session(s) as Done to proceed with creation.');
			} else {
				this.$bvModal.show("add-inventory-session");
			}
		},

		listenerCallback(type, inventorySession) {
			if ((!this.allInventorySessionsObj[inventorySession.id] && type === "added") || type === "modified") {
				this.allInventorySessionsObj[inventorySession.id] = inventorySession;
				this.filterInventorySessions();
			}
		},

		updateTable(inventorySessionObj) {
			if (_.isEmpty(inventorySessionObj)) {
				return;
			}
			this.allInventorySessionsObj[inventorySessionObj.id] =
				inventorySessionObj;

			this.filterInventorySessions();
		},

		dateFromDisabled(_ymd, date) {
			return date > new Date();
		},
		isValidAssetCode(assetCode) {
			return _.isEmpty(assetCode) ||
				ValidationUtil.isValidAssetCode(this.allAssetTypesObj, assetCode);
		},
		validateFilter() {
			let isValid = true;

			if (_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo)) {
				this.$toaster.warning('Date From and Date To are required.');
				isValid = false;
			} else if ((_.isEmpty(this.filterBy.dateFrom) && !_.isEmpty(this.filterBy.dateTo)) ||
				(!_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo))) {
				this.$toaster.warning('Invalid Date Range. Date From and Date To must both have value.');
				isValid = false;
			} else if (this.filterBy.dateFrom > this.filterBy.dateTo) {
				this.$toaster.warning('Invalid Date Range. Date From must be less than Date To.');
				isValid = false;
			} else if (DateUtil.getNoOfDays(this.filterBy.dateFrom, this.filterBy.dateTo) > 90) {
				this.$toaster.warning('Invalid Date Range. Data range is allowed up to 90 days difference.');
				isValid = false;
			} else if (!this.isValidAssetCode(this.filterBy.assetCode)) {
				this.$toaster.warning(`Invalid Asset Code. "${this.filterBy.assetCode}" doesn't follow any of your asset tagging format.`);
				isValid = false;
			}

			return isValid;
		},

		async onFilterRequest() {
			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			if (!this.validateFilter()) {
				return;
			}

			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				await this.retrieveData();
				this.prevFilter = { ...this.filterBy };
			}
		},
		async resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = { ...this.defaultFilterBy };
				this.prevFilter = { ...this.filterBy };

				// reset validation
				this.$validator.reset();
				this.errors.clear();

				await this.retrieveData();
			}
		},
		getFilterParam() {
			let filter = { ...this.filterBy };
			filter.fromTimestamp = DateUtil.startDateTimeStamp(new Date(filter.dateFrom));
			filter.toTimestamp = DateUtil.endDateTimeStamp(new Date(filter.dateTo));

			return filter;
		},
		async retrieveData() {
			try {
				// show loading indicator
				this.isLoading = true;

				let filter = this.getFilterParam();
				filter.companyId = this.loggedUserCompany.id;

				if (this.isSuperAdmin) {
					const { data } = await sessionAPI.getInventorySessions(
						filter,
						config.view.ADMIN,
						this.loggedUser.id
					);
					this.allInventorySessionsObj = data.inventorySessions;
				} else {
					const { data } = await sessionAPI.getInventorySessions(
						filter,
						config.view.COMPANY,
						this.loggedUser.id
					);
					this.allInventorySessionsObj = data.inventorySessions;
				}

				this.filterInventorySessions();

				// Update listener
				this.inventorySessionListener = inventorySessionDAO.getInventorySessionListener(filter, this.listenerCallback);
			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
		filterInventorySessions() {
			let filteredObjs = { ...this.allInventorySessionsObj };

			let fromTimestamp = DateUtil.startDateTimeStamp(new Date(this.filterBy.dateFrom));
			let toTimestamp = DateUtil.endDateTimeStamp(new Date(this.filterBy.dateTo));

			_.forEach(filteredObjs, (session, id) => {
				this.filterByCompany(filteredObjs, session, id);

				if (session.dateCreated < fromTimestamp && session.dateCreated > toTimestamp) {
					delete filteredObjs[id];
				}

				let status = this.filterBy.status;
				if (status && status.length > 0 && status !== session.status) {
					delete filteredObjs[id];
				}

				let sessionId = this.filterBy.sessionId;
				if (sessionId && sessionId.length > 0 && sessionId !== session.sessionId) {
					delete filteredObjs[id];
				}

				let assetCode = this.filterBy.assetCode;
				if (assetCode && assetCode.length > 0 && !session.scannedAssets.includes(assetCode)) {
					delete filteredObjs[id];
				}
			});

			this.processInventorySessions(filteredObjs);
		},
		filterByCompany(filteredObjs, session, id) {
			let companyIdFilter = this.filterBy.company.id;
			let companyId = session.companyId;
			if ((companyIdFilter && companyIdFilter !== companyId) || !UserUtil.hasCompanyAccess(this.allCompaniesObj, companyId)) {
				delete filteredObjs[id];
			}
		},
		processInventorySessions(inventorySessions) {
			this.items = Object.values(inventorySessions);
			this.items = _.sortBy(this.items, ['dateCreated']);
			this.items.reverse();

			_.forEach(this.items, item => {
				item['Storage Location'] = InventorySessionUtil.getCurrStorageLocationName(item);
				item['Date Created'] = DateUtil.getFormattedDateWithTime(item.dateCreated);
				item['Date Updated'] = DateUtil.getFormattedDateWithTime(item.dateUpdated);
				item['Date Cancelled'] = DateUtil.getFormattedDateWithTime(item.dateCancelled);
				item['Total'] = _.size(item.scannedAssets);

				// remove show details
				delete item._showDetails;
			});
			this.totalRows = this.items.length;
			this.$store.dispatch('updateAllInventorySessions', inventorySessions);

			// refresh table
			if (this.$refs.inventoriesTable) {
				this.$refs.inventoriesTable.refresh();
			}
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},

		// UTILS
		showFormattedDate(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	},

	beforeUnmount() {
		if (this.inventorySessionListener != null) {
			// Stop listening to changes
			this.inventorySessionListener();
		}
	},

	beforeDestroy() {
		EventBus.$off('onCloseSaveSession');
	},
};
</script>