<template>
	<b-card>
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
		<b-tabs>
			<b-tab title="Details" active lazy>
				<b-row class="mt-4 mb-2">
					<b-col sm="4" class="md-left-text-sm-right">
						<b>DESCRIPTION</b>
					</b-col>
				</b-row>
				<b-row class="mb-2">
					<b-col>
						<span class="truncate-text">
							<truncate collapsed-text-class="collapsed" clamp="Show More" :length="100" less="Show Less"
								type="html" :text="row.item.description" />
						</span>
					</b-col>
				</b-row>
			</b-tab>
			<b-tab title="List of Scanners" lazy>
				<div>
					<b-row class="mt-4 mb-2">
						<b-col sm="3" class="text-sm-left">
							<b>Email</b>
						</b-col>
						<b-col sm="2" class="text-sm-left">
							<b>Name</b>
						</b-col>
						<b-col sm="2" class="text-sm-left">
							<b>Status</b>
						</b-col>
						<b-col sm="2" class="text-sm-left">
							<b>Total Scanned</b>
						</b-col>
						<b-col sm="2" class="text-sm-center">
							<b>View Asset(s)</b>
						</b-col>
					</b-row>
					<b-row class="mb-2" v-bind:key="scanner.id" v-for="scanner in row.item.scanners">
						<b-col sm="3" class="text-sm-left">{{ scanner.id }}</b-col>
						<b-col sm="2" class="text-sm-left">{{ scanner.name }}</b-col>
						<b-col sm="2" class="text-sm-left">{{
							scanner.isDone === 'true' ? 'Done' : 'On-Going'
						}}</b-col>
						<b-col sm="2" class="text-sm-left numFont">{{
							scanner.scannedAssets.length
						}}</b-col>
						<b-col sm="2" class="text-sm-center">
							<b-button size="sm" @click="showScannedAssets(scanner)"
								v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark" class="mr-1">
								<i class="fa fa-eye"></i>
							</b-button>
						</b-col>
					</b-row>
				</div>
			</b-tab>
			<b-tab title="List of Assets" lazy @click="activateEllipsis()">
				<div v-if="row.item.scannedAssets">
					<b-row class="mt-4 mb-2">
						<b-col sm="12" class="md-left-text-sm-right">
							<span>
								<b-card class="mt-2">
									<div class="row">
										<div class="col">
											<b>Assets</b>
										</div>
										<div class="col-auto">
											<p class="card-text main-total">
												Total:
												<span class="numFont">
													{{ scannedAssets ? scannedAssets.length : 0 }}
												</span>
											</p>
										</div>
									</div>
									<span class="caption">
										Valid assets included in the inventory
									</span>

									<!-- For Input Scanned Assets -->
									<div class="mt-1">
										<div class="my-3">
											<div class="total scan">Scan Total: <span class="numFont">{{ scanTotal }}</span>
											</div>
											<div class="total manual">
												Manual Total: <span class="numFont">{{ manualTotal }}</span>
											</div>
										</div>
										<div :ref="'divAssetScan' + row.item.sessionId" class="truncate">
											<span :key="asset" v-for="asset in scannedAssets">
												&nbsp;

												<b-badge pill :variant="getVariant(asset)" class="asset-pill numFont">
													{{ asset }}
												</b-badge>
											</span>
										</div>
										<div :style="{ display: 'none' }">
											<a class="see-more" :ref="'aAssetScan' + row.item.sessionId" href="#"
												@click.prevent="
													toggleNotes(
														'divAssetScan' + row.item.sessionId,
														'aAssetScan' + row.item.sessionId
													)
													">
												See more
											</a>
										</div>
									</div>
								</b-card>
							</span>
						</b-col>
					</b-row>

					<b-row class="mt-4 mb-2">
						<b-col sm="6" class="md-left-text-sm-right">
							<b-card class="mt-2">
								<div class="row">
									<div class="col">
										<b>In-Transit Assets</b>
									</div>
									<div class="col-auto">
										<p class="card-text main-total">
											Total:
											<span class="numFont">
												{{ inTransitAssets ? inTransitAssets.length : 0 }}
											</span>
										</p>
									</div>
								</div>
								<span class="caption">Assets currently being transported</span>
								<div class="mt-1">
									<div :ref="'divInTransitAsset' + row.item.sessionId" class="truncate">
										<span :key="asset" v-for="asset in inTransitAssets">
											&nbsp;
											<b-badge pill variant="success" class="asset-pill numFont">
												{{ asset }}
											</b-badge>
										</span>
									</div>
									<div :style="{ display: 'none' }">
										<a class="see-more" :ref="'aInTransitAsset' + row.item.sessionId" href="#"
											@click.prevent="
												toggleNotes(
													'divInTransitAsset' + row.item.sessionId,
													'aInTransitAsset' + row.item.sessionId
												)
												">
											See more
										</a>
									</div>
								</div>
							</b-card>
						</b-col>

						<b-col sm="6" class="md-left-text-sm-right">
							<b-card class="mt-2">
								<div class="row">
									<div class="col">
										<b>Deleted Assets</b>
									</div>
									<div class="col-auto">
										<p class="card-text main-total">
											Total:
											<span class="numFont">
												{{ deletedAssets ? deletedAssets.length : 0 }}
											</span>
										</p>
									</div>
								</div>
								<span class="caption">
									Assets deleted during inventory session
								</span>
								<div class="mt-1">
									<div :ref="'divDeletedAsset' + row.item.sessionId" class="truncate">
										<span :key="asset" v-for="asset in deletedAssets">
											&nbsp;
											<b-badge pill variant="secondary" class="asset-pill numFont">
												{{ asset }}
											</b-badge>
										</span>
									</div>
									<div :style="{ display: 'none' }">
										<a class="see-more" :ref="'aDeletedAsset' + row.item.sessionId" href="#"
											@click.prevent="
												toggleNotes(
													'divDeletedAsset' + row.item.sessionId,
													'aDeletedAsset' + row.item.sessionId
												)
												">
											See more
										</a>
									</div>
								</div>
							</b-card>
						</b-col>
					</b-row>
				</div>
			</b-tab>
		</b-tabs>

		<b-row class="mt-4 mb-2">
			<b-col sm="12" class="text-sm-right">
				<b-button size="sm" @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover.top="'Hide Details'">
					<i class="icon-arrow-up"></i>
				</b-button>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';

export default {
	name: 'inventory-details-view',
	components: {
		Loading,
		truncate,
	},
	props: {
		row: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			reconAssets: [],
			reconInputAssetsLog: {
				manual: [],
				scan: [],
			},
			isLoading: false,
		};
	},
	computed: {
		scannedAssets() {
			return this.row.item.scannedAssets ? this.row.item.scannedAssets : [];
		},
		inTransitAssets() {
			return this.row.item.inTransitAssets ? this.row.item.inTransitAssets : [];
		},
		deletedAssets() {
			return this.row.item.assetsToDelete ? this.row.item.assetsToDelete : [];
		},
		manualTotal() {
			let inventorySession = this.row.item;
			let inputAssetLog = inventorySession.inputAssetLog;
			if (!_.isEmpty(inputAssetLog)) {
				let manualAssets = inputAssetLog.manual;
				return manualAssets.length;
			}
			return 0;
		},
		scanTotal() {
			let inventorySession = this.row.item;
			let inputAssetLog = inventorySession.inputAssetLog;
			if (!_.isEmpty(inputAssetLog)) {
				let scannedAssets = inputAssetLog.scan;
				return scannedAssets.length;
			}
			return 0;
		},
	},
	mounted() {
		this.retrieveReconAssets();
	},
	methods: {
		getVariant(asset) {
			let inventorySession = this.row.item;
			let inputAssetLog = inventorySession.inputAssetLog;
			let scannedAssets = inputAssetLog.scan;
			if (scannedAssets.includes(asset)) {
				return 'primary';
			} else {
				return 'dark';
			}
		},
		showFormattedDate(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		retrieveReconAssets() {
			// reset
			this.reconAssets = [];

			let inventorySession = this.row.item;
			let inputAssetLog = inventorySession.inputAssetLog;
			let manualInput = [];
			let scan = [];

			this.reconAssets.forEach((item) => {
				inputAssetLog.manual.forEach((assetCode) => {
					if (item.assetCode === assetCode) {
						manualInput.push(assetCode);
					}
				});
				inputAssetLog.scan.forEach((assetCode) => {
					if (item.assetCode === assetCode) {
						scan.push(assetCode);
					}
				});
			});

			this.reconInputAssetsLog = {
				manual: manualInput,
				scan: scan,
			};
		},

		showReconAssets() {
			EventBus.$emit('onUpdateSelReconAssets', [
				this.row.item,
				this.reconAssets,
			]);
			this.$bvModal.show('recon-assets-view');
		},
		showScannedAssets(scanner) {
			this.scanner = scanner;

			let scan = [];
			let manual = [];

			if (this.row.item.inputAssetLog) {
				let inputAssetLog = this.row.item.inputAssetLog;

				this.scanner.scannedAssets.forEach((asset) => {
					if (inputAssetLog.scan) {
						for (const assetScanned of inputAssetLog.scan) {
							if (asset === assetScanned) {
								scan.push(assetScanned);
								break;
							}
						}
					}
					if (inputAssetLog.manual) {
						for (const assetManual of inputAssetLog.manual) {
							if (asset === assetManual) {
								manual.push(assetManual);
								break;
							}
						}
					}
				});
			}

			this.scanner.inputAssetLog = {
				scan,
				manual,
			};

			EventBus.$emit('onUpdateSelScannedAssetsView', this.scanner);
			this.$bvModal.show('scanned-assets-view');
		},
		toggleNotes(divRefId, aRefId) {
			let elementArray = this.$refs[divRefId];
			if (elementArray) {
				if (elementArray instanceof Array) {
					let className = elementArray[0].className;
					if (className === 'truncate') {
						this.$refs[divRefId][0].className = null;
						this.$refs[aRefId][0].text = 'See less';
					} else {
						this.$refs[divRefId][0].className = 'truncate';
						this.$refs[aRefId][0].text = 'See more';
					}
				} else {
					let className = elementArray.className;
					if (className === 'truncate') {
						this.$refs[divRefId].className = null;
						this.$refs[aRefId].text = 'See less';
					} else {
						this.$refs[divRefId].className = 'truncate';
						this.$refs[aRefId].text = 'See more';
					}
				}
			}
		},
		activateEllipsis() {
			this.$nextTick(() => {
				let elementArray = Array.from(
					document.getElementsByClassName('truncate')
				);
				elementArray.forEach((element) => {
					if (element.offsetHeight < element.scrollHeight) {
						element.parentElement.lastElementChild.style = null;
					}
				});
			});
		},
	},
};
</script>

<style scoped>
.caption {
	font-style: italic !important;
	font-size: medium !important;
}

.total {
	margin-top: 2px;
	font-size: medium !important;
	font-weight: 500;

}

.main-total {
	font-weight: 480;
}

.manual {
	color: #30363b !important;
}

.scan {
	color: #21a8d8 !important;
}

.asset-pill {
	font-size: 14px !important;
}

.see-more {
	font-size: 11px !important;
}
</style>
